import {EnumUtils} from 'src/app/utils/enum-utils';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {LocaleList} from '../../../../locale/locale';
import {PictureFormat} from '../../../settings/data/picture-format';

export class PreferencesMasterSettingsLayout {
	public static layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'preferences'
		},
		{
			attribute: 'locale',
			label: 'locale',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: LocaleList.map((locale) => {return {label: locale.name, value: locale.code};})
		},
		{
			attribute: 'pictureMaxSize',
			label: 'pictureMaxSize',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			inUnit: 'px',
			unit: 'px',
			options: [
				{value: 'px', label: 'px'}
			],
			isActive: true
		},
		{
			attribute: 'pictureFormat',
			label: 'pictureFormat',
			type: UnoFormFieldTypes.OPTIONS,
			isActive: true,
			options: EnumUtils.values(PictureFormat).map((format: string) => {return {label: format.toUpperCase(), value: format};})
		}
	];
}

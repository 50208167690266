import {Component, OnInit, signal, ViewChild, ViewEncapsulation, WritableSignal} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {Session} from 'src/app/session';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {SortDirection} from 'src/app/utils/sort-direction';
import {DashboardPinnedModes} from 'src/app/models/dashboards/dashboard-pinned-modes';
import {UUID} from 'src/app/models/uuid';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {UnoTabComponent} from 'src/app/components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from 'src/app/components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {WidgetsMap} from 'src/app/models/dashboards/widgets-map';
import {UnoFilterBarComponent} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {DashboardListParams, DashboardService} from '../../services/dashboard.service';
import {UnoDashboardComponent} from '../../data/components/dashboard/dashboard.component';

// Possible display modes for the dashboard main page.
export enum DashboardMainPageDisplayMode {
	// Display the pinned dashboards.
	PINNED = 1,
	// Display the dashboard list.
	LIST = 2
};

@Component({
	selector: 'dashboard-main-page',
	templateUrl: 'dashboard-main.page.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UnoFilterBarComponent,
		UnoSearchbarComponent,
		UnoResponsiveTableListComponent,
		UnoButtonComponent,
		TranslateModule,
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoDashboardComponent
	]
})
export class DashboardMainPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app = App;

	public selfStatic = DashboardMainPage;

	public session = Session;

	public dashboardMainPageDisplayMode = DashboardMainPageDisplayMode;

	public displayMode: WritableSignal<DashboardMainPageDisplayMode> = signal(DashboardMainPageDisplayMode.PINNED);

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 30;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The dashboards that are pinned
	 */
	public pinnedDashboards: Dashboard[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', tag: ListDisplayStyle.TITLE, sortBy: 'dashboard.name'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', tag: ListDisplayStyle.LABEL, sortBy: 'dashboard.description'},
		{
			header: 'pinned',
			type: UnoTableColumnType.ICONS,
			attribute: 'pinned',
			visible: true,
			size: 'small',
			sortBy: 'dashboard.pinned',
			iconNumber: 2,
			icons: [{
				src: './assets/dashboards/pin-icon.svg',
				iconColor: (row): string => {
					return row.pinned ? 'var(--brand-primary)' : 'var(--gray-10)';
				},
				click: async(row): Promise<void> => {
					await this.pinDashboard(row);
				}
			}]
		},
		{header: 'createdAt', type: UnoTableColumnType.DATE, attribute: 'createdAt', visible: true, size: 'small', tag: ListDisplayStyle.TEXT, sortBy: 'dashboard.created_at'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: true, size: 'small', tag: ListDisplayStyle.TEXT, sortBy: 'dashboard.updated_at'}
	];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('dashboard');

		this.getPinned();

		await this.countTable();

		const actions = [];
		if (Session.hasPermissions(UserPermissions.DASHBOARDS_EDIT)) {
			actions.push({
				src: './assets/icons/assets/edit-icon.svg',
				click: (row): void => {
					App.navigator.navigate('/menu/dash-boards/edit', {uuid: row.uuid});
				}
			});
		}

		if (Session.hasPermissions(UserPermissions.DASHBOARDS_DELETE)) {
			actions.push({
				src: './assets/icons/uno/bin.svg',
				click: (row): void => {
					this.deleteDashboard(row.uuid);
				}
			});
		}

		if (actions.length > 0) {
			this.tableLayout.push({
				header: 'actions',
				type: UnoTableColumnType.ICONS,
				attribute: 'actions',
				visible: true,
				size: 'small',
				icons: actions
			});
		}
	}

	/**
	 * Possible database filter to be used for ordering the inspection workflow list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'dashboard.updated_at',
			options: [
				{label: 'name', value: 'dashboard.name'},
				{label: 'createdAt', value: 'dashboard.created_at'},
				{label: 'updatedAt', value: 'dashboard.updated_at'},
				{label: 'description', value: 'dashboard.description'},
				{label: 'pinned', value: 'dashboard.pinned'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'pinned',
			label: 'pinned',
			default: DashboardPinnedModes.DASHBOARDS_ALL,
			options: [
				{label: 'all', value: DashboardPinnedModes.DASHBOARDS_ALL},
				{label: 'pinned', value: DashboardPinnedModes.DASHBOARDS_PINNED},
				{label: 'notPinned', value: DashboardPinnedModes.DASHBOARDS_NOT_PINNED}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter items by.
		 */
		search: '',

		/**
		 * Attribute used to sort the items on the list.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the list.
		 */
		sortDirection: SortDirection.ASC,

		/**
		 * Search only for pinned dashboards
		 */
		pinned: DashboardPinnedModes.DASHBOARDS_ALL
	}, DashboardMainPage.filterOptions);

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: DashboardListParams = {
			from: count,
			count: pageSize,
			search: DashboardMainPage.filters.search,
			searchFields: DashboardMainPage.filters.searchFields,
			sortField: DashboardMainPage.filters.sortField,
			sortDirection: DashboardMainPage.filters.sortDirection,
			pinned: DashboardMainPage.filters.pinned
		};

		const list = await DashboardService.list(params);
		const dashboards: any[] = list.dashboards;

		return {
			elements: dashboards,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearchChange(search: string): Promise<void> {
		DashboardMainPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		DashboardMainPage.filters = filters;
		await this.reset();
	}

	/**
	 * When the user changes the sort using the table.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === DashboardMainPage.filters.sortField) {
			DashboardMainPage.filters.sortDirection = this.table.sortDirection;
		} else {
			DashboardMainPage.filters.sortField = sortBy;
			DashboardMainPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	public async countTable(): Promise<void> {
		const params: DashboardListParams = {
			search: DashboardMainPage.filters.search,
			searchFields: DashboardMainPage.filters.searchFields,
			pinned: DashboardMainPage.filters.pinned
		};

		this.tableTotalItemsCount = await DashboardService.count(params);

	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		this.table.sortDirection = DashboardMainPage.filters.sortDirection;
		this.table.sortField = DashboardMainPage.filters.sortField;


		if (this.table) {
			await this.countTable();
			await this.table.reset();
		}
	}

	/**
	 * Delete a dashboard after confirmation.
	 * 
	 * @param dashboardUuid - UUID of the dashboard to delete
	 */
	public async deleteDashboard(dashboardUuid: UUID): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDelete'), ['yes', 'no']);
		if (confirm) {
			await DashboardService.delete(dashboardUuid);
			Modal.toast(Locale.get('deleteSuccessfully'));

			await this.reset();
		}
	}

	/**
	 * Pin a dashboard.
	 * 
	 * @param dashboard - The dashboard to pin.
	 */
	public async pinDashboard(dashboard: Dashboard): Promise<void> {
		dashboard.pinned = !dashboard.pinned;

		const dash = await DashboardService.get(dashboard.uuid);
		dash.pinned = dashboard.pinned;
		await DashboardService.update(dash);
	}

	/**
	 * Get all the pinned dashboards
	 */
	public async getPinned(): Promise<void> {
		// Get all pinned dashboards
		const req = await DashboardService.widgetList({pinned: DashboardPinnedModes.DASHBOARDS_PINNED, sortField: '[dashboard].[index]', sortDirection: SortDirection.ASC});
	
		// Filter if the user has at least one permission
		this.pinnedDashboards = req.dashboards.filter((dashboard) => {
			for (const widget of dashboard.widgets) {
				const widgetType = WidgetsMap.get(widget.type);
				if (Session.hasPermissions(widgetType.permissions)) {
					return true;
				}
			}
			return true;
		});
	}


	/**
	 * Set the display mode and confirm the pinned dashboards.
	 * 
	 * @param mode - The dashboard to pin.
	 */
	public async setDisplay(mode: DashboardMainPageDisplayMode): Promise<void> {
		this.displayMode.set(mode);

		// Refresh the pinned dashboards.
		if (mode === DashboardMainPageDisplayMode.PINNED) {
			await this.getPinned();
		}
	}

}

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardMainPage} from './screens/main/dashboard-main.page';
import {DashboardEditPage} from './screens/edit/dashboard-edit.page';

const routes: Routes = [
	{
		path: '',
		component: DashboardMainPage
	},
	{
		path: 'edit',
		component: DashboardEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardsRouterModule { }

import {Pipe, PipeTransform} from '@angular/core';
import {Locale} from '../locale/locale';
import {Session} from '../session';
import {DateFormat} from '../modules/settings/data/date-format';
import {TimeFormat} from '../modules/settings/data/time-format';

/**
 * Pipe to format date and show it as a locale representation based on the system configuration.
 */
@Pipe({
	name: 'formatDate',
	standalone: true,
	pure: true
})
export class FormatDatePipe implements PipeTransform {
	public transform(d: (string | Date), time: Boolean = true): string {
		return FormatDatePipe.formatDateTime(d, time);
	}

	/**
	 * Format date and time based on application configuration.
	 * 
	 * @param d - Date to be formated.
	 * @param time - If the time is to be displayed or not.
	 * @returns Date as string.
	 */
	public static formatDateTime(d: (string | Date), time: Boolean = true): string {
		return FormatDatePipe.formatDate(d) + (time ? ' ' + FormatDatePipe.formatTime(d) : '');
	}

	/**
	 * Format date based on application configuration.
	 * 
	 * @param d - Date to be formated.
	 * @returns Date as string.
	 */
	public static formatDate(d: (string | Date)): string {
		const date = new Date(d);
		let formatted = '';

		if (Session.settings.dateFormat === DateFormat.DDMMYYYY) {
			formatted += date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
		} else if (Session.settings.dateFormat === DateFormat.MMDDYYYY) {
			formatted += date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
		} else if (Session.settings.dateFormat === DateFormat.YYYYMMDD) {
			formatted += date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		} else {
			formatted += date.toLocaleDateString(Locale.code);
		}
	
		return formatted;
	}

	/**
	 * Format time of the date into a string.
	 * 
	 * @param d - Date to be formatted
	 * @returns 
	 */
	public static formatTime(d: (string | Date)): string {
		const date = new Date(d);
		let formatted = '';

		if (Session.settings.timeFormat === TimeFormat.HH12) {
			formatted += new Intl.DateTimeFormat('default', {
				hour12: true,
				hour: 'numeric',
				minute: 'numeric'
			}).format(date);
		} else if (Session.settings.timeFormat === TimeFormat.HH24) {
			formatted += new Intl.DateTimeFormat('default', {
				hour12: false,
				hour: 'numeric',
				minute: 'numeric'
			}).format(date);
		} else {
			formatted += date.toLocaleTimeString(Locale.code, {
				hour: 'numeric',
				minute: 'numeric'
			});
		}

		return formatted;
	}
}

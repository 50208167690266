import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {UnoIconComponent} from 'src/app/components/uno/uno-icon/uno-icon.component';
import {NFC} from 'src/app/utils/nfc/nfc';
import {NFCHandler} from 'src/app/utils/nfc/nfc-handler';
import {ValidationUtils} from 'src/app/utils/validation-utils';
import {App} from '../../../app';
import {Modal} from '../../../modal';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {Environment} from '../../../../environments/environment';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {AssetInfoComponent} from '../../qr/components/asset-info/asset-info.component';

@Component({
	selector: 'nfc-page',
	templateUrl: 'nfc.page.html',
	standalone: true,
	imports: [
		UnoContentComponent,
		TranslateModule,
		UnoIconComponent
	]
})
export class NFCPage extends ScreenComponent implements OnInit, OnDestroy {
	public permissions = [UserPermissions.ASSET_PORTFOLIO_NFC];

	/**
	 * Callback method if specified it is used to process the data received from NFC tags.
	 */
	public onRead: (serial: string)=> void = null;

	/**
	 * Indicates if the API is waiting for an API response.
	 */
	public waitingAPI: boolean = false;

	/**
	 * Enables/Disables NFC scan
	 */
	public enabled: boolean = false;

	/**
	 * The Asset UUID associated with the NFC.
	 */
	public asset: APAsset = null;

	/**
	 * Handler to read NFC data.
	 */
	public nfc: NFCHandler = null;

	/**
	 * Whether or not the backdrop is active.
	 */ 
	public backdrop: boolean = false;

	public contentStyle: any = {
		display: 'flex',
		'align-items': 'center',
		'flex-direction': 'column',
		'justify-content': 'center'
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.nfc = await NFC.read((result: any) => {
			this.process(result.serialNumber);
		});

		this.onRead = null;
		this.enabled = true;

		const data = App.navigator.getData();
		if (data && data.onRead) {
			this.onRead = data.onRead;
		}

		App.navigator.setTitle('nfcReader');
	}

	public ngOnDestroy(): void {
		if (this.nfc) {
			this.nfc.destroy();
		}
	}

	/**
	 * Process the NFC tag value. Navigate to the asset edit page if a valid asset is found for the given NFC tag.
	 * 
	 * @param nfc - NFC tag value.
	 */
	public async process(nfc: string): Promise<void> {
		if (!Environment.PRODUCTION) {
			console.log('EQS: Read NFC tag data:', nfc);
		}

		if (this.onRead !== null) {
			this.onRead(nfc);
		} else if (!this.waitingAPI) {
			this.waitingAPI = true;

			try {
				this.asset = await AssetService.getByNFC(nfc, false, false);
			} catch (e) {}
			try {
				if (ValidationUtils.isUUID(nfc)) {
					this.asset = await AssetService.get(nfc);
				}
			} catch (e) {}
			if (this.asset) {
				this.backdrop = true;
				await Modal.component(AssetInfoComponent, {asset: this.asset}, false, false);
				this.backdrop = false;
			}
			this.waitingAPI = false;
		}
	}
}

<div class="uno-password" [ngClass]="{'uno-password-error': !this.valid() && !this.disabled}">
	<input #password type="password" (change)="this.updateValue($event)" [ngClass]="{'.uno-password-disabled': this.disabled}">
	<uno-icon (click)="togglePassword()" [src]="this.icon()" color="gray" [width]="32" [height]="32"></uno-icon>
</div>

@if (this.validator) {
	@if (this.validator.successMessage && this.valid() && !this.disabled) {
		<uno-text [ngStyle]="{'font-size': '12px', 'height':'12px'}" color="success">{{this.validator.successMessage | translate}}</uno-text>
	}
	@if (this.validator.errorMessage && !this.valid() && !this.disabled) {
		<uno-text [ngStyle]="{'font-size': '12px', 'height':'12px'}" color="error">{{this.validator.errorMessage | translate}}</uno-text>
	}
}

<div class="uno-atex-tag-group">
	<div style="display: flex; justify-content: space-between;">
		<span class="uno-p2">{{'atexField' | translate}}</span>
		<uno-icon color="primary" src="assets/components/global-calendar/add-icon.svg" [height]="24" [width]="24" (click)="this.addTag()"></uno-icon>
	</div>

	@if (this.value !== null) {
		<div style="display: flex; gap: 5px; flex-direction: column;">
			@for (tag of this.value.tags; track i; let i = $index) {
				<uno-atex-tag [disabled]="this.disabled" [ngModel]="tag" (onUpdateGroup)="this.updateValue()" (onDelete)="this.removeTag(i)" [ngModelOptions]="{standalone: true}" slot="end"></uno-atex-tag>
			}
		</div>
	}
</div>

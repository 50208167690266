import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';

export const SegmentLayoutSimplified: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		editable: false,
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		label: 'material',
		attribute: 'material',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		label: 'thickness',
		attribute: 'thickness',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		inUnit: 'm',
		unit: 'm',
		options: [
			{value: 'mm', label: 'mm'},
			{value: 'cm', label: 'cm'},
			{value: 'm', label: 'm'},
			{value: 'km', label: 'km'}
		]
	}
];

import {DashboardBarChartComponent} from 'src/app/components/dashboard/bar-chart/dashboard-bar-chart';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {AssetService} from 'src/app/modules/asset-portfolio/services/asset.service';
import {AssetTypeListParams, AssetTypeService} from 'src/app/modules/asset-portfolio/services/asset-type.service';
import {APAssetType} from 'src/app/models/asset-portfolio/asset-type';
import {Locale} from 'src/app/locale/locale';
import {DashboardDoughnutChartComponent} from 'src/app/components/dashboard/doughnut-chart/dashboard-doughnut-chart.component';
import {CSSUtils} from 'src/app/utils/css-utils';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {AssetSubtypeListParams, AssetSubTypeService} from 'src/app/modules/asset-portfolio/services/asset-subtype.service';
import {APAssetSubType} from 'src/app/models/asset-portfolio/asset-sub-type';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {Modal} from 'src/app/modal';
import {cloneDeep} from 'lodash-es';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {AssetDistributionType} from 'src/app/models/dashboards/asset-distribution-type';
import {BaseWidgetLayout} from '../../widget-layout';
import {ChartData} from '../../components/widget/widget.component';


export class WidgetAssetDistribution {
	public displayType = WidgetTypes.DASHBOARD_WIDGET_TYPE_ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE;

	public visualization = [DashboardBarChartComponent, DashboardDoughnutChartComponent];

	public static permissions: number[] = [];

	public static async getData(widgetData: DashboardWidget): Promise<ChartData> {
		const labels: string[] = [];
		const counts: number[] = [];
		let totalCount: number;

		let distribution: APAssetType[]|APAssetSubType[] = [];

		if (widgetData.data.distribution === AssetDistributionType.TYPE) {
			WidgetAssetDistribution.permissions = [UserPermissions.ASSET_PORTFOLIO_ASSET_TYPE];
			// If there are types selected.
			if (widgetData.data.assetTypeUuids && widgetData.data.assetTypeUuids.length > 0) {
				const request = await AssetTypeService.getBatch(widgetData.data.assetTypeUuids, true, false);
				distribution = request;
			} else {
				const req = await AssetTypeService.list({count: 10}, true, false);
				distribution = req.types;
			}

		} else if (widgetData.data.distribution === AssetDistributionType.SUBTYPE) {
			WidgetAssetDistribution.permissions = [UserPermissions.ASSET_PORTFOLIO_ASSET_SUBTYPE];
			// If there are subtypes selected.
			if (widgetData.data.assetSubtypeUuids && widgetData.data.assetSubtypeUuids.length > 0) {
				const request = await AssetSubTypeService.getBatch(widgetData.data.assetSubtypeUuids, true, false);
				distribution = request;
			} else if (widgetData.data.assetType) {
				const req = await AssetSubTypeService.list({typeUuid: widgetData.data.assetType, count: 10}, true, false);
				distribution = req.subTypes;
			}
				
			if (widgetData.data.showOtherAssets && widgetData.data.assetType) {
				totalCount = await AssetService.count({typeUuid: widgetData.data.assetType});
			}
		} else {
			throw new Error('EQS: Widget distribution type is incorrect/does not exist');
		}

		// Count number of assets
		for (const type of distribution) {
			const data = widgetData.data.distribution === AssetDistributionType.SUBTYPE ? {subtypeUuid: type.uuid} : {typeUuid: type.uuid};
			const count = await AssetService.count(data, true, false);

			labels.push(type.name);
			counts.push(count);
		}

		if (widgetData.data.distribution === AssetDistributionType.SUBTYPE && widgetData.data.showOtherAssets && widgetData.data.assetType ) {
			labels.push(Locale.get('others'));
			counts.push(totalCount - counts.reduce((sum, num) => {return sum + num;}, 0));
		}

		const dataset = [{
			label: Locale.get('asset'),
			data: counts,
			backgroundColor: CSSUtils.getVariable('--brand-primary'),
			categoryPercentage: 0.6,
			barPercentage: 0.6
		}];

		return {labels: labels, datasets: dataset};
	};

	/**
	 * Opens the edit modal for the asset distribution widget and returns the updated widget.
	 * 
	 * @param widget - The widget to update.
	 * @returns Updated widget after the modal is closed
	 */
	public static async openEditModal(widget: DashboardWidget): Promise<DashboardWidget> {
		let editLayout = cloneDeep(BaseWidgetLayout);
		if (widget.data.distribution === AssetDistributionType.TYPE) {
			editLayout.push({
				required: false,
				label: 'types',
				attribute: 'data.assetTypeUuids',
				multiple: true,
				identifierAttribute: 'uuid',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
					const params: AssetTypeListParams = {
						from: request.from,
						count: request.count,
						search: request.search
					};

					const req = await AssetTypeService.list(params);
					return {options: req.types, hasMore: req.hasMore, id: req.id};
				},
				fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> => {
					const types: APAssetType[] = await AssetTypeService.getBatch(request.options);
					return {options: types};
				},
				getOptionText: (option: any): string => {
					return option.name + (option.description ? ' - ' + option.description : '');
				}
			});

			widget.data['assetTypeUuids'] = widget.data['assetTypeUuids'] ?? [];

			// Reset the type and subtype (used for subtype distribution mode)
			widget.data['assetType'] = null;
			widget.data['assetSubtypeUuids'] = [];
		} else {
			editLayout = editLayout.concat([{
				required: false,
				label: 'type',
				attribute: 'data.assetType',
				multiple: false,
				identifierAttribute: 'uuid',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
					const params: AssetTypeListParams = {
						from: request.from,
						count: request.count,
						search: request.search
					};

					const req = await AssetTypeService.list(params);
					return {options: req.types, hasMore: req.hasMore, id: req.id};
				},
				fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> => {
					const types: APAssetType = await AssetTypeService.getType(request.options[0]);
					return {options: [types]};
				},
				getOptionText: (option: any): string => {
					return option.name + (option.description ? ' - ' + option.description : '');
				},
				onChange: function(object: any, row: UnoFormField) {
					object.subtypes = null;
				}
			},
			{
				required: false,
				label: 'subTypes',
				attribute: 'data.assetSubtypeUuids',
				multiple: true,
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				identifierAttribute: 'uuid',
				isActive: (object: any, row: UnoFormField) => {
					return Boolean(object.data.assetType);
				},
				fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
					const params: AssetSubtypeListParams = {
						from: request.from,
						count: request.count,
						search: request.search,
						typeUuid: object.data.assetType
					};

					const req = await AssetSubTypeService.list(params);
					return {options: req.subTypes, hasMore: req.hasMore, id: req.id};

				},
				fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> => {
					if (request.options[0]) {
						return {options: [await AssetSubTypeService.getSubtype(request.options[0])]};
					}
					return {options: []};
				},
				getOptionText: (option: any): string => {
					return option.name + (option.description ? ' - ' + option.description : '');
				}
			},
			{
				required: false,
				label: 'showOthersSum',
				attribute: 'data.showOtherAssets',
				type: UnoFormFieldTypes.CHECKBOX
			}]);

			// Reset the types array (used for type distribution mode)
			widget.data['assetTypeUuids'] = [];

			widget.data['assetType'] = widget.data['assetType'] ? widget.data['assetType'] : null;
			widget.data['assetSubtypeUuids'] = widget.data['assetSubtypeUuids'] ? widget.data['assetSubtypeUuids'] : [];
		}

		await Modal.form(Locale.get('edit'), widget, editLayout);

		return widget;
	}
}

/**
 * Utils to validate data types and information.
 *
 * Used to validate data on the client before sending it to the server.
 */
export class ValidationUtils {
	/**
	 * Regex to validate emails syntax.
	 */
	public static emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	/**
	 * Regex to validate phone numbers.
	 */
	public static phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*((\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})|(\d{2,3})[-. ]*(\d{3})[-. ]*(\d{3}))\s*$/;

	/**
	 * Regex to for dial code in a phone number.
	 */
	public static dialCodeRegex = /\+\d+(?= )/;

	/**
	 * Regex to validate is a string contains a valid numeric value.
	 *
	 * Allows for integer and decimal values.
	 */
	public static numberRegex = /^(?!-0?(\.0+)?$)-?(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;

	/**
	 * Regex to validate if a string contains a valid UUID.
	 */
	public static uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

	/**
	 * Check if the string represents a valid UUID.
	 *
	 * @param uuid - UUID to be tested.
	 * @returns True if the input represents a valid UUID.
	 */
	public static isUUID(uuid: string): boolean {
		return ValidationUtils.uuidRegex.test(uuid);
	}

	/**
	 * Check if the string represents a valid email address.
	 *
	 * @param email - Email to be tested.
	 * @returns True if the input represents a valid email address.
	 */
	public static isEmail(email: string): boolean {
		return ValidationUtils.emailRegex.test(String(email).toLowerCase());
	}

	/**
	 * Check if the text represents a valid password.
	 *
	 * A valid password in the UNO platform has at least 8 characters.
	 *
	 * @param text - Text to be tested.
	 * @returns True if text represents a valid password.
	 */
	public static validPassword(text: string): boolean {
		return text && text.length >= 8;
	}


	/**
	 * Check if the text represents a valid phone number.
	 *
	 * @param text - Text to be tested.
	 * @returns True if text represents a valid phone number.
	 */
	public static validPhoneNumber(text: string): boolean {
		return ValidationUtils.phoneRegex.test(text);
	}

	/**
	 * Check if the text represents a valid number.
	 *
	 * @param text - Text to be tested.
	 * @returns True if text represents a valid number.
	 */
	public static validNumber(text: string): boolean {
		return ValidationUtils.numberRegex.test(text);
	}
}



import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {ExperimentDirection, ExperimentDirectionLabel} from '../../../../../models/pipeline-integrity/mot/experiment-directions';

export const MotExperimentLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: false,
		attribute: 'label',
		label: 'label',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: true,
		attribute: 'frequency',
		label: 'frequency',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		inUnit: 'Hz',
		unit: 'Hz',
		options: [
			{value: 'Hz', label: 'Hz'},
			{value: 'kHz', label: 'kHz'},
			{value: 'MHz', label: 'MHz'}
		]
	},
	{
		required: false,
		attribute: 'direction',
		label: 'direction',
		type: UnoFormFieldTypes.OPTIONS,
		options: Object.values(ExperimentDirection).map(function(value) {
			return {value: value, label: ExperimentDirectionLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'waveChannels',
		label: 'waveChannels',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'propagationSpeed',
		label: 'propagationSpeed',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: true,
		attribute: 'dateTime',
		label: 'date',
		type: UnoFormFieldTypes.DATE
	},
	{
		required: true,
		attribute: 'acquisitionUuid',
		label: 'acquisition',
		type: UnoFormFieldTypes.TEXT,
		editable: false
	}
];

import {Resource} from '../../resource';
import {Geolocation} from '../../geolocation';
import {UUID, UUIDIdentification} from '../../uuid';
import {RepairLongevity} from './repair-longevity';
import {RepairStatus} from './repair-status';
import {RepairCriticalityLevel} from './repair-criticality';

export class Repair extends UUIDIdentification {
	/**
	 * Status of the repair.
	 */
	public status: number = RepairStatus.PROPOSAL;

	/**
	 * UUID of the Asset that this repair refers to (not required) can be null.
	 */
	public asset: UUID = null;

	/**
	 * Description of the repair.
	 */
	public description: string = '';

	/**
	 * QR identifier of this repair.
	 */
	public qr: string = null;

	/**
	 * Repair criticality level.
	 */
	public criticality: number = RepairCriticalityLevel.NORMAL;

	/**
	 * Pictures of the damage/issue to repair
	 */
	public pictures: Resource[] = [];

	/**
	 * Technical documents of the asset/damage/issue to repair and every meaningful information
	 */
	public technicalDocuments: Resource[] = [];

	/**
	 * GPS position of the issue to fix.
	 */
	public position: Geolocation = null;

	/**
	 * Text notes pointed by the reporter
	 */
	public notes: string = '';

	/**
	 * Identify a repair as temporary or definitive
	 */
	public longevity: number = RepairLongevity.UNKNOWN;

	/**
	 * The type of temporary repair.
	 */
	public temporaryRepairTypeUuid: UUID = null;

	/**
	 * The repair cost in € (only currency for now)
	 */
	public cost: number = null;

	/**
	 * The UUID of company which will execute the repair
	 */
	public company: UUID = null;

	/**
	 * The documents of the repair proposal
	 */
	public proposalDocuments: Resource[] = [];

	/**
	 * Any further notes regarding repair proposal
	 */
	public proposalNotes: string = '';

	/**
	 * Document containing bill of materials needed on repair
	 */
	public billOfMaterials: Resource[] = [];

	/**
	 * If a repair needs welding jobs or not. If affirmative, insert welding documents and notes
	 */
	public needsWelding: boolean = false;

	/**
	 * Documents relative to the welding job
	 */
	public weldings: RepairWelding[] = [];

	/**
	 * If repair will handle fluids of class 1,2 or 3, it needs a risk assessment.
	 */
	public handleFluids: boolean = false;

	/**
	 * Risk assessment documents
	 */
	public riskAssessmentDocuments: Resource[] = [];

	/**
	 * Risk assessment notes
	 */
	public riskAssessmentNotes: string = '';

	/**
 	* List of Non-Destructive Tests entries for this repair
 	*/
	public nonDestructiveTests: RepairNDTProcedure[] = [];

	/**
	 * Repair agenda, submitted on proposal approval by client
	 */
	public repairOrder: string = '';

	/**
	 * Any further notes regarding repair proposal approval (or maybe rejects on future, to show why it was rejected)
	 */
	public proposalApprovalNotes: string = '';

	/**
	 * The installation date of this repair.
	 */
	public installationDate: Date = null;

	/**
	 * Job related pictures to register the work progress
	 */
	public jobPictures: Resource[] = [];

	/**
	 * Job related documents to register the work progress
	 */
	public jobDocuments: Resource[] = [];

	/**
	 * Job related notes to register the work progress
	 */
	public jobNotes: string = '';

	/**
	 * QA related pictures.
	 */
	public qaPictures: Resource[] = [];

	/**
	 * QA related documents.
	 */
	public qaDocuments: Resource[] = [];

	/**
	 * QA related notes.
	 */
	public qaNotes: string = '';

	/**
	 * Justification why the repair is blocked.
	 */
	public blockedJustification: string = '';

	/**
	 * Documents regarding unlock
	 */
	public blockedDocuments: Resource[] = [];

	/**
	 * Notes regarding unlock
	 */
	public blockedNotes: string = '';

	/**
	 * Proposal/Repair rejection message
	 */
	public rejectionMessage: string = '';

	/**
	 * The expected removal date of this repair.
	 */
	public expectedRemovalDate: Date = null;

	/**
	 * The repair removal date (when archived).
	 */
	public removalDate: Date = null;

	/**
	 * Parse the data of an API retrieved Repair object.
	 *
	 * @param data - Repair object to parse data
	 */
	public static parse(data: any): Repair {
		const repair = new Repair();

		repair.uuid = data.uuid;
		repair.createdAt = new Date(data.createdAt);
		repair.updatedAt = new Date(data.updatedAt);
		repair.status = data.status;
		repair.description = data.description;

		// Repair Base
		repair.qr = data.qr;
		repair.asset = data.asset;
		repair.criticality = data.criticality;
		repair.pictures = Resource.parseArray(data.pictures);
		repair.technicalDocuments = Resource.parseArray(data.technicalDocuments);
		repair.position = Geolocation.parse(data.position);
		repair.notes = data.notes;

		// Repair Proposal
		repair.longevity = data.longevity;
		repair.temporaryRepairTypeUuid = data.temporaryRepairTypeUuid;
		repair.company = data.company;
		repair.cost = data.cost === -1 ? null : data.cost;
		repair.proposalDocuments = Resource.parseArray(data.proposalDocuments);
		repair.proposalNotes = data.proposalNotes;
		repair.billOfMaterials = Resource.parseArray(data.billOfMaterials);

		// Welding data
		repair.needsWelding = data.needsWelding;
		repair.weldings = !data.weldings ? [] : data.weldings;

		// Fluids
		repair.handleFluids = data.handleFluids;
		repair.riskAssessmentDocuments = Resource.parseArray(data.riskAssessmentDocuments);
		repair.riskAssessmentNotes = data.riskAssessmentNotes;

		// NDT
		repair.nonDestructiveTests = !data.nonDestructiveTests ? [] : data.nonDestructiveTests;

		// Proposal approval
		repair.repairOrder = data.repairOrder ? data.repairOrder : '';
		repair.proposalApprovalNotes = data.proposalApprovalNotes;

		// Job
		repair.installationDate = data.installationDate ? new Date(data.installationDate) : null;
		repair.jobPictures = Resource.parseArray(data.jobPictures);
		repair.jobDocuments = Resource.parseArray(data.jobDocuments);
		repair.jobNotes = data.jobNotes;

		// Quality assurance
		repair.qaPictures = Resource.parseArray(data.qaPictures);
		repair.qaDocuments = Resource.parseArray(data.qaDocuments);
		repair.qaNotes = data.qaNotes;

		// Blocked
		repair.blockedJustification = data.blockedJustification;
		repair.blockedDocuments = Resource.parseArray(data.blockedDocuments);
		repair.blockedNotes = data.blockedNotes;
		
		// Repair rejection message
		repair.rejectionMessage = data.rejectionMessage;
		
		// Management
		repair.expectedRemovalDate = data.expectedRemovalDate ? new Date(data.expectedRemovalDate) : null;
		repair.removalDate = data.removalDate ? new Date(data.removalDate) : null;

		return repair;
	}
}

/**
 * Repair welding applied in the repair process.
 */
export class RepairWelding {
	/**
	 * Weld number identification.
	 */
	public weldNumber: string = '';

	/**
	 * Welder identification.
	 */
	public welder: string = '';

	/**
	 * Welding document code.
	 */
	public documentCode: string = '';

	/**
	 * Consumables used in the repair job
	 */
	public consumables: number[] = [];

	/**
	 * Numeric ID of the welding standards.
	 */
	public standards: number[] = [];

	/**
	 * Numeric ID of the welding procedure.
	 */
	public procedures: number[] = [];

	/**
	 * Numeric ID of the welding type.
	 */
	public types: number[] = [];

	/**
	 * Indicates if the welding is licensed.
	 */
	public licensed: boolean = false;

	/**
	 * Documents related to the weld.
	 */
	public documents: Resource[] = [];

	/**
	 * Notes about the weld.
	 */
	public notes: string = null;
}

/**
 * Repair non destructive test entries on repair object (and non destructive test form)
 */
export class RepairNDTProcedure {
	/**
	 * Numeric ID of the RepairNDT.
	 */
	public ndt: number = null;

	/**
	 * Percentage of test
	 */
	public percentage: number = null;
	
	/**
	 * Documents 
	 */
	public documents: Resource[] = [];

	/**
	 * Notes
	 */
	public notes: string = null;
}

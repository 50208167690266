import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {StringUtils} from 'src/app/utils/string-utils';
import {InspectionReport} from 'src/app/modules/inspections/data/inspection/inspection-report';
import {UUID} from '../../app/models/uuid';
import {InspectionForm} from '../../app/models/inspections/form/inspection-form';
import {InspectionFormField, OptionsData} from '../../app/models/inspections/form/inspection-form-field';
import {InspectionFormFieldType} from '../../app/modules/inspections/data/form/inspection-form-field-type';
import {Inspection} from '../../app/models/inspections/inspection/inspection';
import {InspectionData, InspectionDataFields} from '../../app/models/inspections/inspection/inspection-data';
import {InspectionWorkflowStep} from '../../app/models/inspections/workflow/inspection-workflow-step';
import {ResourceUtils} from '../../app/utils/resource-utils';

/**
 * The possible inspection results after evaluating a checklist answer.
 * 
 * Expected values between 1 (very good) and 5 (very bad)
 */
const InspectionResult = {
	VERY_GOOD: 1,
	GOOD: 2,
	SATISFACTORY: 3,
	BAD: 4,
	VERY_BAD: 5
};

/**
 * The possible key word answers and respective values on evaluation scale that a checklist answer may have.
 * 
 * Sentences with two or more words must be set before the single words to prevent mismatches as it is expected to stop on first match. This is: the option "bom" would match sentences with "...bom..." and "...muito bom..." on it but it should stop the test of the sentence against the options before if "muito bom" was previously found on sentence, never reaching the test against "bom" option.
 */
export const AnswerOptions: Array<[string, number]> = [
	['muitobom', InspectionResult.VERY_GOOD],
	['muitomau', InspectionResult.VERY_BAD],
	['bom', InspectionResult.GOOD],
	['mau', InspectionResult.BAD],
	['satisfatorio', InspectionResult.SATISFACTORY]
];

/**
 * "Negative" (values higher than 3) answered key questions that contain this text on it will overcome the whole step average value with the checklist answer.
 */
export const NonTestedOption: [string, number] = ['naotestado', InspectionResult.VERY_BAD];

/**
 * "Not Applicable" answered key questions that contain this text on it will be set as null and doesn't count for the inspection average.
 */
export const NotApplicableOption: [string, number] = ['naoaplicavel', null];

/**
 * Contains all the checklists qualitative and quantitative data
 */
export class ChecklistEvaluationData {
	/**
	 * Fields data to add to the row on exported file
	 */
	public rowData: string[] = [];

	/**
	 * Average of the form checklist responses. Expected value between 1 (very good) and 5 (very bad).
	 */
	public answersAvg: number = null;

	/**
	 * The worst key question evaluation found on the checklist. If a key question has a "negative answer" (higher than 3), the inspection average does not matter.
	 * 
	 * The worst key question evaluation is used as the final evaluation of the inspection step.
	 */
	public worstKeyQuestion: number = null;

	/**
	 * Creates a new instance of ChecklistEvaluationData.
	 * 
	 * @param rowData - Fields data to add to the row on exported file
	 * @param answersAvg - The average of the form checklist responses. Expected value between 1 (very good) and 5 (very bad).
	 * @param worstKeyQuestion - The worst key question evaluation found on the checklist. If a key question has a "negative answer" (higher than 3), the inspection average does not matter.
	 */
	public constructor(rowData: string[] = [], answersAvg: number = null, worstKeyQuestion: number = null) {
		this.rowData = rowData;
		this.answersAvg = answersAvg;
		this.worstKeyQuestion = worstKeyQuestion;
	}
}

/**
 * Meaningfull data to be extracted to the summary sheet on export.
 */
export class InspectionAverageAFIAssessmentData {
	/**
	 * The global appreciation given to an inspection
	 */
	public overallAssessment: number | string = null;
	
	/**
	 * The asset status evaluated by an inspector
	 */
	public assetStatus: string = '';
}

export class InspectionAverageAFI {
	/**
	 * The UUID of a specific field regarding the overall assessment
	 */
	private static overallAssessmentFieldUuid: UUID = '7d3b7028-6e1b-195c-9cf8-6f9975975ad2';

	/**
	 * The UUID of a specific field regarding the asset status evaluation
	 */
	private static assetStatusFieldUuid: UUID = 'bdc9d50d-68f2-1c37-91be-b967ad07adf1';

	/**
	 * Util to format the value of the field based on its value and the type of form field.
	 * 
	 * @param value - The value to format.
	 * @param field - The form field to get the type from.
	 * @returns The formatted value.
	 */
	private static formatValue(value: any, field: InspectionFormField): string {
		if (value === null || value === undefined) {
			return value;
		}

		if (field.type === InspectionFormFieldType.IMAGES || field.type === InspectionFormFieldType.DOCUMENTS) {
			return value.map(function(a) { return ResourceUtils.getURL(a);}).join(', ');
		} else if (field.type === InspectionFormFieldType.AUDIO || field.type === InspectionFormFieldType.VIDEO) {
			return ResourceUtils.getURL(value);
		} else if (value instanceof Object) {
			return JSON.stringify(value);
		}

		return value.toString();
	}

	/**
	 * Extracts a key questions from a list of xlsx file rows.
	 * 
	 * Key questions are "more important" questions that will take extra importance when computing inspection evaluation. When a key question has a "negative answer" (higher than 3), the inspection average does not matter. It is used the worst key question evaluation as the inspection step final evaluation.
	 * 
	 * @param rows - The array of rows from the XLSX file.
	 * @returns An array with checklists key question codes.
	 */
	public static extractKeyQuestionCodes(rows: any[]): string[] {
		// Read all the checklist key question codes
		const keyQuestionCodes: string[] = [];
		for (let i = 0; i < rows.length; i++) {
			const c: any = XlsxUtils.readRow(rows[i], ['Codes']);
			if (c !== null) {
				keyQuestionCodes.push(c);
			}
		}

		return keyQuestionCodes;
	}

	/**
	 * Indentifies a form field as a key question or not using a given the field label and a list of key question codes.
	 * 
	 * @param field - The form field to identify as key question or not.
	 * @param codes - The list of key question codes
	 * @returns True if it is a key question, false otherwise
	 */
	public static isKeyQuestionField(field: InspectionFormField, codes: string[]): boolean {
		if (codes.length <= 0) {
			return false;
		}

		// Currently, the key questions are identified on the field label attribute as a prefix for the label separated with a "|"
		const labelPrefix: string = field.label.split('|')[0];

		// Check if it is a key question by iterating over codes list and check if they are found on field label
		if (labelPrefix.length > 0) {
			for (let i = 0; i < codes.length; i++) {
				if (Number(labelPrefix) === Number(codes[i])) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Evaluates the data of a step inspection checklist average and all its field qualitative and quantitative values.
	 * 
	 * @param formUuid - The UUID of the checklist form.
	 * @param checklistData - The data object to get the data from.
	 * @param formsMap - Map with checklist forms.
	 * @param codes - A list of key question codes.
	 * @param worstKeyQuestion - The worst key question found on the inspection step. If a key question has a "negative answer" (higher than 3), the inspection average does not matter.
	 * @param assessmentData - The assessment data filled in the inspection to be exported on summary sheet.  (optional)
	 * @returns The step inspection checklist average value and all its field qualitative and quantitative values to be exported.
	 */
	public static evaluateChecklistData(formUuid: UUID, checklistData: InspectionDataFields, formsMap: Map<UUID, InspectionForm>, codes: string[], worstKeyQuestion: number = -1, assessmentData?: InspectionAverageAFIAssessmentData): ChecklistEvaluationData {
		if (!formsMap.has(formUuid)) {
			throw new Error('Form with UUID ' + formUuid + ' not found in the provided map.');
		}

		const form: InspectionForm = formsMap.get(formUuid);

		// Store the checklist responses values to compute its average
		const checklistValues: number[] = [];

		const data: ChecklistEvaluationData = new ChecklistEvaluationData();

		for (let n = 0; n < form.fields.length; n++) {
			const field: InspectionFormField = form.fields[n];

			if (checklistData) {
				// Get the value of the field.
				let fieldData: any = checklistData[field.uuid];
				const isOptions = field.type === InspectionFormFieldType.OPTIONS || field.type === InspectionFormFieldType.OPTIONS_MULTIPLE;

				// If field is of new options type, the text used for the option UUID must be fetched too
				if (isOptions) {
					// Extract the answer label from fields' data by answer value (option UUID)
					const d: OptionsData = field.data as OptionsData;
					if (field.data && d.options && d.options instanceof Array) {
						const option: any = d.options.find((opt) => {return opt.key === fieldData;});
						fieldData = option ? option.value : '';
					}
				}
					
				// Convert the field data response to its correspondent numeric value
				if (isOptions && fieldData && typeof fieldData === 'string') {
					const isKeyQuestion: boolean = InspectionAverageAFI.isKeyQuestionField(field, codes);
					const answerValue: number = InspectionAverageAFI.extractAnswerQuantitativeValue(fieldData, isKeyQuestion);

					if (answerValue) {
						checklistValues.push(answerValue);
							
						// If a worse nagative value is found on a key question, update the average value of the inspection step
						if (isKeyQuestion && answerValue > worstKeyQuestion) {
							worstKeyQuestion = answerValue;
						}

						// Add answer and answer quantitative values to the row data to be exported
						data.rowData = data.rowData.concat([InspectionAverageAFI.formatValue(fieldData, field), String(answerValue)]);
					} else {
						// Add answer and answer quantitative values to the row data to be exported
						data.rowData = data.rowData.concat([InspectionAverageAFI.formatValue(fieldData, field), null]);
					}

					
					if (assessmentData) {
						// Inspectors team global evaluation (static UUID)
						if (field.uuid === InspectionAverageAFI.overallAssessmentFieldUuid) {
							assessmentData.overallAssessment = InspectionAverageAFI.extractAnswerQuantitativeValue(fieldData, false) || fieldData;
						}

						// Asset status evaluation (static UUID)
						if (field.uuid === InspectionAverageAFI.assetStatusFieldUuid) {
							assessmentData.assetStatus = fieldData;
						}
					}
				} else {
					// Add answer and answer quantitative values to the row data to be exported
					data.rowData = data.rowData.concat([InspectionAverageAFI.formatValue(fieldData, field), null]);
				}
			} else {
				// Add null answer and answer quantitative values to the row data to be exported
				data.rowData = data.rowData.concat([null, null]);
			}
		}

		data.answersAvg = checklistValues.reduce((sum: number, val: number): number => {
			return sum + val;
		}, 0) / checklistValues.length;

		data.worstKeyQuestion = worstKeyQuestion;

		return data;
	}

	/**
	 * Extract field answer quantitative value from its textual answer.
	 * 
	 * @param fieldAnswer - The textual answer given for a specific checklist field.
	 * @param isKeyQuestion - Indicates if this is a key question field.
	 * @returns The quantitative value of the given answer, regarding if it is a key question or not.
	 */
	public static extractAnswerQuantitativeValue(fieldAnswer: string, isKeyQuestion: boolean): number {
		// Extract the answer prefix to analyse for matches on the available scale of options
		const answerPrefix: string = StringUtils.normalize(fieldAnswer.split('-')[0]);
		if (answerPrefix.length === 0) {
			return null;
		}
	
		if (answerPrefix === NonTestedOption[0]) {
			// Non tested answers value are null if it is not a key question. If it is a key question, the worst value is set for the answer (5)
			return isKeyQuestion ? NonTestedOption[1] : null;
		} else {
			// Analyse field answer value to match the possible answer option values
			for (const option of AnswerOptions) {
				if (answerPrefix === option[0]) {
					return option[1];
				}
			}
		}

		return null;
	}
	
	/**
	 * Evaluates the data of an inspection step
	 * 
	 * @param step - The step to evaluate the data for.
	 * @param inspection - The inspection to extract the data to evaluate.
	 * @param codes - The key questions codes.
	 * @param formsMap - A map with all the forms and sub-forms of the inspection step.
	 * @param assessmentData - The assessment data filled in the inspection to be exported on summary sheet.
	 * @returns The step average value as well as all its data to be exported with qualitative and quantitative answers with average values per checklist.
	 */
	public static async evaluateStepData(step: InspectionWorkflowStep, inspection: Inspection, codes: string[], formsMap: Map<UUID, InspectionForm>, assessmentData?: InspectionAverageAFIAssessmentData): Promise<ChecklistEvaluationData> {
		const stepData: InspectionData = inspection.getInspectionDataForStep(step.uuid);
		
		// The worst evaluation given on a key question for this step. "Negative" values (4 or 5) will replace the inspection average value.
		let worstKeyQuestion: number = -1;

		// Get/update step forms map
		let stepForm: InspectionForm;
		if (step.formUuid) {
			formsMap = await InspectionReport.loadForms(step.formUuid, formsMap);
			stepForm = formsMap.get(step.formUuid);
		}

		const checklistsAverages: number[] = [];
		let stepRowData: any[] = [];

		if (stepForm) {
			// Evaluate all the step checklists average values and extract its data to export
			for (let i = 0; i < stepForm.fields.length; i++) {
				const stepFormField: InspectionFormField = stepForm.fields[i];
				
				const data: InspectionDataFields | UUID = stepData ? stepData.data[stepFormField.uuid] : null;
				if (stepFormField.type === InspectionFormFieldType.SUB_FORM || stepFormField.type === InspectionFormFieldType.COMPOSED_FIELD) {
					const fieldData: InspectionDataFields = data as InspectionDataFields;
					const evaluatedData: ChecklistEvaluationData = InspectionAverageAFI.evaluateChecklistData(stepFormField.subFormUuid, fieldData ? fieldData : null, formsMap, codes, worstKeyQuestion, assessmentData);
					
					// Update the worst key question found on the inspection step
					if (worstKeyQuestion < evaluatedData.worstKeyQuestion) {
						worstKeyQuestion = evaluatedData.worstKeyQuestion;
					}

					const checklistAvg: number = !isNaN(evaluatedData.answersAvg) ? evaluatedData.answersAvg : null;
					stepRowData = stepRowData.concat([checklistAvg, ...evaluatedData.rowData]);
					
					// Store checklist value to be used on step final average value. Only checklists with valid answers are considered to the average computation
					checklistsAverages.push(checklistAvg);
				} else {
					let value: any;
					
					// If field is of new options type, the text used for the option UUID must be fetched too
					if (stepFormField.type === InspectionFormFieldType.OPTIONS) {					// Extract the answer label from fields' data by answer value (option UUID)
						const stepFormFieldData: OptionsData = stepFormField.data as OptionsData;

						if (stepFormFieldData && stepFormFieldData.options && stepFormFieldData.options instanceof Array) {
							const option: any = stepFormFieldData.options.find((opt) => {return opt.key === data;});
							value = option ? option.value : '';
						}
					} else if (stepFormField.type === InspectionFormFieldType.OPTIONS_MULTIPLE) {
						const optionsData: string[] = [];

						// Extract the answer label from fields' data by answer value (option UUID)
						const stepFormFieldData: OptionsData = stepFormField.data as OptionsData;
						if (stepFormField.data && stepFormFieldData.options && stepFormFieldData.options instanceof Array) {
							for (let j = 0; j < data.length; j++) {
								const option: any = stepFormFieldData.options.find((opt) => {return opt.key === data[j];});
								optionsData.push(option ? option.value : '');
							}
						}
						
						value = optionsData.join(' | ');
					}

					stepRowData = stepRowData.concat([this.formatValue(value, stepFormField), null]);
				}
			}
		}

		const stepEvaluatedData: ChecklistEvaluationData = new ChecklistEvaluationData();

		// Negative key questions set the average value of the inspection step
		if (worstKeyQuestion >= InspectionResult.BAD) {
			stepEvaluatedData.answersAvg = worstKeyQuestion;
		} else {
			// Filter invalid checklist averages and compute the final step average value
			const validChecklistsAverages: number[] = checklistsAverages.filter((avg: number) => {
				return avg && !isNaN(avg);
			});

			stepEvaluatedData.answersAvg = validChecklistsAverages.reduce((sum: number, avg: number): number => {
				return sum + avg;
			}, 0) / validChecklistsAverages.length;
		}
		
		// Append checklist average value and all its qualitative and quantitative answers
		stepEvaluatedData.rowData = stepEvaluatedData.rowData.concat(stepRowData);
		
		// return stepAvg;
		return stepEvaluatedData;
	}
}

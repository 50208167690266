<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearchChange($event)"></uno-searchbar>

	<!-- Filters -->
	<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%; height:80px">
		<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>

		<div style="height:80px; padding-bottom: 10px; gap: 5px; display: flex; align-items: flex-end;">
			<uno-button [disabled]="this.selectedRepairUuids.length === 0" (click)="(this.selectedRepairUuids.length > 0) && this.exportXLSX()">
				{{'exportXlsx' | translate}}
			</uno-button>
		</div>
	</div>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-responsive-table-list [selectable]="true" (rowChecked)="this.checkedRow($event)" [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="this.tablePageSize" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/repairs/works/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy);">
	</uno-responsive-table-list>
</uno-content>

import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {RepairInspectionResultLabel} from 'src/app/models/repairs/inspections/repair-inspection-result';
import {ServiceResponse} from 'src/app/http/service-response';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {ResourceUtils} from '../../../../utils/resource-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {ProgressBar} from '../../../../progress-bar';
import {UUID} from '../../../../models/uuid';
import {RepairStatusLabel} from '../../../../models/repairs/repairs/repair-status';
import {RepairCriticalityLevelLabel} from '../../../../models/repairs/repairs/repair-criticality';
import {RepairLongevityLabel} from '../../../../models/repairs/repairs/repair-longevity';
import {RepairTemporaryTypeService} from '../services/repair-temporary-types.service';

/**
 * Tools to export repair data from the platform.
 */
export class RepairExport {
	/**
	 * Export repairs list as XLSX file.
	 * 
	 * It can export all the repairs (default behaviour) or just some repairs by their UUIDs when provided.
	 * 
	 * @param repairUuids - The UUIDs of the repairs to export (optional).
	 */
	public static async exportXLSX(repairUuids?: UUID[]): Promise<void> {
		const headers: {name: string, fields: string[]}[] = [
			{
				name: Locale.get('asset'),
				fields: [
					Locale.get('uuid'), Locale.get('name'), Locale.get('tag'), Locale.get('manufacturer'), Locale.get('model'), 
					Locale.get('fluidFamilyUuid'), Locale.get('fluidFamily'), Locale.get('fluidTypeUuid'), Locale.get('fluidType')]
			},
			{
				name: Locale.get('repair'),
				fields: [
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('description'),
					Locale.get('status'), Locale.get('criticality'), Locale.get('pictures'), Locale.get('technicalDocuments'), 
					Locale.get('latitude'), Locale.get('longitude'), 
					Locale.get('notes'),
					Locale.get('longevity'), Locale.get('repairType'),
					Locale.get('cost'), Locale.get('companyUuid'), Locale.get('company'), Locale.get('proposalDocuments'), Locale.get('proposalNotes'),
					Locale.get('billOfMaterials'),
					Locale.get('needsWelding'), Locale.get('handleFluids'), 
					Locale.get('riskAssessmentDocuments'), Locale.get('riskAssessmentNotes'),
					Locale.get('repairOrder'), Locale.get('proposalApprovalNotes'), 
					Locale.get('installationDate'), Locale.get('jobPictures'), Locale.get('jobDocuments'), Locale.get('jobNotes'),
					Locale.get('qaPictures'), Locale.get('qaDocuments'), Locale.get('qaNotes'),
					Locale.get('blockedJustification'), Locale.get('blockedDocuments'), Locale.get('blockedNotes'), 
					Locale.get('rejectionMessage'), 
					Locale.get('expectedRemovalDate'), Locale.get('removalDate')
				]
			},
			{
				name: Locale.get('inspection'),
				fields: [
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), Locale.get('result')
				]
			}
		];

		const rowData: string[][] = [];

		rowData.push(headers.reduce(function(acc: any, value: any) {
			return acc.concat([value.name].concat(new Array(value.fields.length - 1).fill('')));
		}, []));

		rowData.push(headers.reduce(function(acc: any, value: any) {
			return acc.concat(value.fields);
		}, []));

		let from: number = 0;
		const count: number = 2000;
		const nd = '';

		const progress = new ProgressBar();
		progress.show();

		const buildRepairRow = (data: {repair: Repair, asset: {uuid: UUID, name: string, tag: string, manufacturer: string, model: string, fluidFamilyUuid: UUID, fluidFamilyLabel: string, fluidTypeUuid: UUID, fluidTypeLabel: string}, inspection: {uuid: UUID, createdAt: Date, updatedAt: Date, result: number}, company: {uuid: UUID, name: string}}, repairTypes: Map<UUID, String>): any[] => {
			let row: any[] = [];
			
			if (data.asset) {				
				row.push(data.asset.uuid, data.asset.name, data.asset.tag, data.asset.manufacturer, data.asset.model, data.asset.fluidFamilyUuid, data.asset.fluidFamilyLabel, data.asset.fluidTypeUuid, data.asset.fluidTypeLabel);
			} else {
				row.push(nd, nd, nd, nd, nd, nd, nd, nd, nd);
			}
		
			row.push(data.repair.uuid, FormatDatePipe.formatDate(data.repair.createdAt), FormatDatePipe.formatDate(data.repair.updatedAt), data.repair.description, Locale.get(RepairStatusLabel.get(data.repair.status)), Locale.get(RepairCriticalityLevelLabel.get(data.repair.criticality)));
		
			let pictures: string = '';
			if (data.repair.pictures) {
				for (let k = 0; k < data.repair.pictures.length; k++) {
					pictures += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.pictures[k]);
				}
			}
			row.push(pictures);

			let technicalDocuments: string = '';
			if (data.repair.technicalDocuments) {
				for (let k = 0; k < data.repair.technicalDocuments.length; k++) {
					technicalDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.technicalDocuments[k]);
				}
			}
		
			row.push(technicalDocuments);
		
			// GPS position
			row = row.concat(data.repair.position ? [data.repair.position.latitude.toString(), data.repair.position.longitude.toString()] : [nd, nd]);
		
			row.push(data.repair.notes, Locale.get(RepairLongevityLabel.get(data.repair.longevity)), data.repair.temporaryRepairTypeUuid ? repairTypes.get(data.repair.temporaryRepairTypeUuid) : nd, data.repair.cost);
		
			// Company
			if (data.company) {
				row.push(data.company.uuid, data.company.name);
			} else {
				row.push(nd, nd);
			}

			let proposalDocuments: string = '';
			if (data.repair.proposalDocuments) {
				for (let k = 0; k < data.repair.proposalDocuments.length; k++) {
					proposalDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.proposalDocuments[k]);
				}
			}
			row.push(proposalDocuments, data.repair.proposalNotes);
		
			let billOfMaterials: string = '';
			if (data.repair.billOfMaterials) {
				for (let k = 0; k < data.repair.billOfMaterials.length; k++) {
					billOfMaterials += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.billOfMaterials[k]);
				}
			}
		
			row.push(billOfMaterials, Locale.get(data.repair.needsWelding ? 'yes' : 'no'), Locale.get(data.repair.handleFluids ? 'yes' : 'no'));
		
			let riskAssessmentDocuments: string = '';
			if (data.repair.riskAssessmentDocuments) {
				for (let k = 0; k < data.repair.riskAssessmentDocuments.length; k++) {
					riskAssessmentDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.riskAssessmentDocuments[k]);
				}
			}
		
			row.push(riskAssessmentDocuments, data.repair.riskAssessmentNotes, data.repair.repairOrder, data.repair.proposalApprovalNotes, data.repair.installationDate ? FormatDatePipe.formatDate(data.repair.installationDate) : nd);
		
			let jobPictures: string = '';
			if (data.repair.jobPictures) {
				for (let k = 0; k < data.repair.jobPictures.length; k++) {
					jobPictures += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.jobPictures[k]);
				}
			}
			row.push(jobPictures);
		
			let jobDocuments: string = '';
			if (data.repair.jobDocuments) {
				for (let k = 0; k < data.repair.jobDocuments.length; k++) {
					jobDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.jobDocuments[k]);
				}
			}
		
			row.push(jobDocuments, data.repair.jobNotes);
			
			let qaPictures: string = '';
			if (data.repair.qaPictures) {
				for (let k = 0; k < data.repair.qaPictures.length; k++) {
					qaPictures += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.qaPictures[k]);
				}
			}
			row.push(qaPictures);
		
			let qaDocuments: string = '';
			if (data.repair.qaDocuments) {
				for (let k = 0; k < data.repair.qaDocuments.length; k++) {
					qaDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.qaDocuments[k]);
				}
			}
			row.push(qaDocuments, data.repair.qaNotes, data.repair.blockedJustification);
		
			let blockedDocuments: string = '';
			if (data.repair.blockedDocuments) {
				for (let k = 0; k < data.repair.blockedDocuments.length; k++) {
					blockedDocuments += (k === 0 ? '' : ', ') + ResourceUtils.getURL(data.repair.blockedDocuments[k]);
				}
			}
			row.push(blockedDocuments, data.repair.blockedNotes, data.repair.rejectionMessage, data.repair.expectedRemovalDate ? FormatDatePipe.formatDate(data.repair.expectedRemovalDate) : nd, data.repair.removalDate ? FormatDatePipe.formatDate(data.repair.removalDate) : nd);

			// Inspection
			if (data.inspection) {
				row.push(data.inspection.uuid, FormatDatePipe.formatDate(data.inspection.createdAt), FormatDatePipe.formatDate(data.inspection.updatedAt), Locale.get(RepairInspectionResultLabel.get(data.inspection.result)));
			} else {
				row.push(nd, nd, nd, nd);
			}

			return row;
		};

		try {
			const total: number = !repairUuids ? (await Service.fetch(ServiceList.repairs.countStatus, null, null, null, Session.session, true)).response.count : repairUuids.length;

			if (total > 0) {
				const repairTypes = await RepairTemporaryTypeService.list(true);
				const repairTypesMap = new Map(repairTypes.types.map((type) => {return [type.uuid, type.label];}));

				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					let request: ServiceResponse;
					if (!repairUuids) {
						request = await Service.fetch(ServiceList.repairs.listDetailed, null, null, {from: from, count: count}, Session.session, true);
					} else if (repairUuids.length > 0) {
						request = await Service.fetch(ServiceList.repairs.getBatch, null, null, {uuids: repairUuids.slice(from, from + count)}, Session.session, true);
					} else {
						// Export file only with headers on it
						progress.update(Locale.get('loadingData'), 1);
						break;
					}

					const response = request.response.repairs;

					// Build row data from repair
					for (const responseLine of response) {
						rowData.push(buildRepairRow(responseLine, repairTypesMap));
					}

					from += count;

					if (!repairUuids && !request.response.hasMore || repairUuids && repairUuids.length > 0 && from >= repairUuids.length - 1) {
						break;
					}
				}
			}
			
			progress.update(Locale.get('loadingData'), 1);
			XlsxUtils.writeFile(rowData, 'repairs.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {RepairCriticalityLevelLabel} from 'src/app/models/repairs/repairs/repair-criticality';
import {QRGenerator} from 'src/app/modules/qr/data/qr-generator';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {AssetFluidFamilyListParams, AssetFluidService, AssetFluidTypeListParams} from 'src/app/modules/asset-portfolio/services/asset-fluid.service';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {RepairInspectionResult} from 'src/app/models/repairs/inspections/repair-inspection-result';
import {AssetPortfolioFiterBarFields} from 'src/app/modules/asset-portfolio/asset-portfolio-filter-bar-fields';
import {Environment} from 'src/environments/environment';
import {RepsolRepairListAssetFilter} from 'src/client-custom/repsol/repair-list-asset-filter';
import {UUID} from 'src/app/models/uuid';
import {PermissionsPipe} from 'src/app/pipes/permissions.pipe';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {RepairLongevity} from '../../../../../models/repairs/repairs/repair-longevity';
import {RepairStatus, RepairStatusLabel} from '../../../../../models/repairs/repairs/repair-status';
import {Locale} from '../../../../../locale/locale';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {RepairCountParams, RepairListParams, RepairListResponse, RepairService} from '../../services/repair.service';
import {RepairTemporaryTypeService} from '../../services/repair-temporary-types.service';
import {RepairExport} from '../../data/repair-export';

@Component({
	selector: 'repairs-list-page',
	templateUrl: 'repairs-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		TranslateModule,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent,
		PermissionsPipe,
		UnoButtonComponent
	]
})
export class RepairsListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent)
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = RepairsListPage;

	public permissions = [UserPermissions.REPAIR_LIST_ALL];

	public userPermissions = UserPermissions;

	/**
	 * List of selected repair items UUIDs from the list.
	 */
	public selectedRepairUuids: UUID[] = [];

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * Status of repair.
	 */
	public status: number = RepairStatus.ALL;

	/**
	 * Last value of the asset fluid type UUID filter
	 * 
	 * Used to compare and reset on change.
	 */
	public lastAssetFluidFamilyUuid = null;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.headerActive('image'), size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: this.headerActive('description'), size: 'small', sortBy: '[repair].[description]'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: this.headerActive('assetName'), size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: this.headerActive('assetTag'), size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'inspectionResult', type: UnoTableColumnType.STATUS, attribute: 'inspectionResult', visible: this.headerActive('inspectionResult'), size: 'small', sortBy: '[repair_inspection].[result]'},
		{header: 'inspectionDate', type: UnoTableColumnType.TEXT, attribute: 'inspectionDate', visible: this.headerActive('inspectionDate'), size: 'small', sortBy: '[repair_inspection].[date]'},
		{header: 'installationDate', type: UnoTableColumnType.DATE, attribute: 'installationDate', visible: this.headerActive('installationDate'), size: 'small', sortBy: '[repair].[installation_date]'},
		{header: 'removalDate', type: UnoTableColumnType.DATE, attribute: 'removalDate', visible: this.headerActive('removalDate'), size: 'small', sortBy: '[repair].[removal_date]'},
		{header: 'expectedRemovalDate', type: UnoTableColumnType.DATE, attribute: 'expectedRemovalDate', visible: this.headerActive('expectedRemovalDate'), size: 'small', sortBy: '[repair].[expected_removal_date]'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: this.headerActive('status'), size: 'small', sortBy: '[repair].[status]'},
		{header: 'createdAt', type: UnoTableColumnType.DATE, attribute: 'createdAt', visible: this.headerActive('createdAt'), size: 'small', sortBy: '[repair].[created_at]'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: this.headerActive('updatedAt'), size: 'small', sortBy: '[repair].[updated_at]'},
		{header: 'criticality', type: UnoTableColumnType.TEXT, attribute: 'criticality', visible: this.headerActive('criticality'), size: 'small', sortBy: '[repair].[criticality]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: this.headerActive('actions'),
			size: 'medium',
			icons: [{
				src: './assets/components/menu/qrcode.svg',
				click: (row): void => {
					QRGenerator.generateFile(row.uuid);
				}
			},
			{
				src: './assets/icons/assets/expand-icon.svg',
				click: (row): void => {
					App.openInTab('/menu/repairs/works/edit', {uuid: row.uuid});
				}
			}]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Repair Inspection list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[repair].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[repair].[updated_at]'},
				{label: 'createdAt', value: '[repair].[created_at]'},
				{label: 'criticality', value: '[repair].[criticality]'},
				{label: 'inspectionResult', value: '[repair_inspection].[result]'},
				{label: 'inspectionDate', value: '[repair_inspection].[date]'},
				{label: 'status', value: '[repair].[status]'},
				{label: 'description', value: '[repair].[description]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'installationDate', value: '[repair].[installation_date]'},
				{label: 'expectedRemovalDate', value: '[repair].[expected_removal_date]'},
				{label: 'removalDate', value: '[repair].[removal_date]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[repair].[id]', '[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[id]', '[repair].[repair_order]', '[repair].[description]', '[repair].[notes]'],
			options: [
				{label: 'uuid', value: '[repair].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'inspectionResult', value: '[repair_inspection].[result]'},
				{label: 'repairOrder', value: '[repair].[repair_order]'},
				{label: 'description', value: '[repair].[description]'},
				{label: 'notes', value: '[repair].[notes]'},
				{label: 'proposalNotes', value: '[repair].[proposal_notes]'},
				{label: 'riskAssessmentNotes', value: '[repair].[risk_assessment_notes]'},
				{label: 'proposalApprovalNotes', value: '[repair].[proposal_approval_notes]'},
				{label: 'blockedNotes', value: '[repair].[blocked_notes]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'longevityFilters',
			label: 'longevity',
			default: [RepairLongevity.UNKNOWN, RepairLongevity.TEMPORARY, RepairLongevity.DEFINITIVE],
			options: [	
				{label: 'unknown', value: RepairLongevity.UNKNOWN},
				{label: 'temporary', value: RepairLongevity.TEMPORARY},
				{label: 'definitive', value: RepairLongevity.DEFINITIVE}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'tableFields',
			label: 'tableFields',
			default: ['image', 'description', 'assetName', 'assetTag', 'status', 'updatedAt', 'actions', 'inspectionResult'],
			options: [
				{label: 'image', value: 'image'},
				{label: 'description', value: 'description'},
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'status', value: 'status'},
				{label: 'createdAt', value: 'createdAt'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'criticality', value: 'criticality'},
				{label: 'actions', value: 'actions'},
				{label: 'inspectionResult', value: 'inspectionResult'},
				{label: 'inspectionDate', value: 'inspectionDate'},
				{label: 'installationDate', value: 'installationDate'},
				{label: 'removalDate', value: 'removalDate'},
				{label: 'expectedRemovalDate', value: 'expectedRemovalDate'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'assetFluidFamilyUuid',
			identifierAttribute: 'uuid',
			label: 'fluidFamily',
			default: null,
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const params: AssetFluidFamilyListParams = {
					from: request.from,
					count: request.count,
					search: request.search
				};

				const req = await AssetFluidService.listFamily(params);
				return {options: req.families, hasMore: req.hasMore, id: req.id};
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options?.length > 0) {
					return {options: [await AssetFluidService.getFamily(request.options[0])]};
				} 

				return {options: []};
			},
			getOptionText: (option: any): string => {
				return option.label;
			}
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'assetFluidTypeUuid',
			identifierAttribute: 'uuid',
			label: 'fluidType',
			default: null,
			multiple: false,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const params: AssetFluidTypeListParams = {
					from: request.from,
					count: request.count,
					search: request.search,
					assetFluidFamilyUuid: RepairsListPage.filters.assetFluidFamilyUuid
				};
				
				const req = await AssetFluidService.listTypes(params);

				return {options: req.types, hasMore: req.hasMore, id: req.id};
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options[0]) {
					return {options: [await AssetFluidService.getType(request.options[0])]};
				} 
				return {options: []};
			},
			getOptionText: (option: any): string => {
				return option.label;
			}
		},
		UnoFilterBarOption.copy(AssetPortfolioFiterBarFields.assetField, {
			attribute: 'parentAssetUuid',
			label: 'parentAsset',
			enabled: (filters: any): boolean => {return Environment.CLIENT_ID !== 'repsol' || !Environment.PRODUCTION;}
		}),
		RepsolRepairListAssetFilter,
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'repairTypeUuid',
			identifierAttribute: 'uuid',
			label: 'repairType',
			default: null,
			multiple: false,
			options: [],
			fetchOptions: async(): Promise<any> => {
				const req = await RepairTemporaryTypeService.list();
				const types = req.types.map((type) => {
					return {label: type.label, value: type.uuid};
				});

				types.unshift({label: 'all', value: null});
				return types;
			}
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter repairs by their name.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Longevity filters
		 */
		longevityFilters: [],

		/**
		 * Asset fluid family UUID
		 * 
		 */
		assetFluidFamilyUuid: null,

		/**
		 * Asset fluid type UUID
		 */
		assetFluidTypeUuid: null,

		/**
		 * Parent asset UUID
		 */
		parentAssetUuid: null,

		/**
		 * Repair type UUID
		 */
		repairTypeUuid: null
	}, RepairsListPage.filterOptions);

	public static defaultFilters = structuredClone(RepairsListPage.filters);

	public resetFilters(): void {
		Object.assign(RepairsListPage.filters, RepairsListPage.defaultFilters);
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		// Read status from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		App.navigator.setTitle(Locale.get(RepairStatusLabel.has(data.status) ? RepairStatusLabel.get(data.status) : 'list'));
		
		this.status = data.status !== undefined ? data.status : RepairStatus.ALL;
		this.selectedRepairUuids = [];
		
		await this.countTableItems();
	}

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: RepairListParams = {
			status: this.status,
			from: count,
			count: pageSize,
			search: RepairsListPage.filters.search,
			sortDirection: RepairsListPage.filters.sortDirection,
			sortField: RepairsListPage.filters.sortField,
			searchFields: RepairsListPage.filters.searchFields,
			longevityFilters: RepairsListPage.filters.longevityFilters,
			assetFluidFamilyUuid: RepairsListPage.filters.assetFluidFamilyUuid,
			assetFluidTypeUuid: RepairsListPage.filters.assetFluidTypeUuid,
			parentAssetUuid: RepairsListPage.filters.parentAssetUuid,
			repairTypeUuid: RepairsListPage.filters.repairTypeUuid
		};

		const list: RepairListResponse = await RepairService.list(params);
		const repairs: any[] = list.repairs;

		repairs.forEach((repair) => {
			repair.image = repair.pictures?.length > 0 ? repair.pictures[0] : './assets/placeholder/asset.png';
			repair.assetName = repair.asset.name;
			repair.assetTag = repair.asset.tag;
			repair.inspectionDate = repair.inspection.date;
			repair.criticality = Locale.get(RepairCriticalityLevelLabel.get(repair.criticality));
			repair.status = Locale.get(RepairStatusLabel.get(repair.status));
			repair.inspectionResult = repair.inspection.result === RepairInspectionResult.COMPLIANT ? 'var(--success-normal)' : repair.inspection.result === RepairInspectionResult.NON_COMPLIANT ? 'var(--error-normal)' : 'var(--gray-10)';
		});
		return {
			elements: list.repairs,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		RepairsListPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		if (this.lastAssetFluidFamilyUuid !== RepairsListPage.filters.assetFluidFamilyUuid) {
			RepairsListPage.filters.assetFluidTypeUuid = null;
		}
	
		this.lastAssetFluidFamilyUuid = RepairsListPage.filters.assetFluidTypeUuid;
		await this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		this.tableLayout.forEach((column) => {
			column.visible = this.headerActive(column.attribute);
		});
      
		if (this.table) {
			this.table.sortDirection = RepairsListPage.filters.sortDirection;
			this.table.sortField = RepairsListPage.filters.sortField;
			await this.table.reset();
		}

		await this.countTableItems();
		await this.table.reset();
	}

	/**
	 * Export the selected repairs.
	 */
	public async exportXLSX(): Promise<void> {
		await RepairExport.exportXLSX(this.selectedRepairUuids);
	}

	/**
	 * Updates when a table row is checked.
	 * 
	 * @param output - The checked rows.
	 */
	public async checkedRow(event: any): Promise<void> {
		if (typeof event.rows === 'boolean') {
			if (!event.rows) {
				this.selectedRepairUuids = [];
			} else {
				const params: RepairListParams = {
					status: this.status,
					search: RepairsListPage.filters.search,
					sortDirection: RepairsListPage.filters.sortDirection,
					sortField: RepairsListPage.filters.sortField,
					searchFields: RepairsListPage.filters.searchFields,
					longevityFilters: RepairsListPage.filters.longevityFilters,
					assetFluidFamilyUuid: RepairsListPage.filters.assetFluidFamilyUuid,
					assetFluidTypeUuid: RepairsListPage.filters.assetFluidTypeUuid,
					parentAssetUuid: RepairsListPage.filters.parentAssetUuid,
					repairTypeUuid: RepairsListPage.filters.repairTypeUuid
				};

				this.selectedRepairUuids = (await RepairService.list(params)).repairs.map((value) => {return value.uuid;});
			}
		} else {
			this.selectedRepairUuids = event.items.map((repair) => { return repair?.uuid; });
		}
	}

	/**
	 * When the user changes the sort using the table
	 *
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === RepairsListPage.filters.sortField) {
			RepairsListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			RepairsListPage.filters.sortField = sortBy;
			RepairsListPage.filters.sortDirection = SortDirection.DESC;
		}

		await this.reset();
	}

	/**
	 * Check if a table header is active
	 *
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active
	 */
	public headerActive(attribute: string): boolean {
		return RepairsListPage.filters.tableFields.indexOf(attribute) !== -1;
	}

	/**
	 * Count the total items in the table and update it.
	 */
	public async countTableItems(): Promise<void> {
		const params: RepairCountParams = {
			status: this.status,
			search: RepairsListPage.filters.search,
			searchFields: RepairsListPage.filters.searchFields,
			longevityFilters: RepairsListPage.filters.longevityFilters,
			assetFluidFamilyUuid: RepairsListPage.filters.assetFluidFamilyUuid,
			assetFluidTypeUuid: RepairsListPage.filters.assetFluidTypeUuid,
			repairTypeUuid: RepairsListPage.filters.repairTypeUuid,
			parentAssetUuid: RepairsListPage.filters.parentAssetUuid
		};

		this.tableTotalItemsCount = await RepairService.count(params);
	}
}

import {EnumUtils} from 'src/app/utils/enum-utils';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {GAGapStatus, GAGapStatusLabel} from '../../../../models/gap-analysis/gaps/gap-status';
import {GAGapPriority, GAGapPriorityLabel} from '../../../../models/gap-analysis/gaps/gap-priority';

/**
 * Stores the form layout to edit gap data.
 */
export const GapLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'status',
		label: 'status',
		editable: false,
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		options: EnumUtils.values(GAGapStatus).map(function(value) {
			return {value: value, label: GAGapStatusLabel.get(value as GAGapStatus)};
		})
	},
	{
		attribute: 'priority',
		label: 'priority',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: EnumUtils.values(GAGapPriority).map(function(value) {
			return {value: value, label: GAGapPriorityLabel.get(value as GAGapPriority)};
		})
	},
	{
		attribute: 'financialImpact',
		label: 'financialImpact',
		inUnit: '€',
		unit: '€',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		options: [
			{value: '€', label: '€'}
		]
	},
	{
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		label: 'documents',
		attribute: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	}
];

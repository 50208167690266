import {TranslateService} from '@ngx-translate/core';
import {LocaleEN} from './locale-en';
import {LocalePT} from './locale-pt';
import {LocaleES} from './locale-es';

export type LocaleSource = {name: string, code: string, translation: any};

/**
 * List of all locales available in the system.
 */
export const LocaleList: LocaleSource[] = [
	{
		name: 'english',
		code: 'en',
		translation: LocaleEN
	},
	{
		name: 'portuguese',
		code: 'pt',
		translation: LocalePT
	},
	{
		name: 'spanish',
		code: 'es',
		translation: LocaleES
	}
];

/**
 * Handles locales related observations, locale data is registered here, locale data should be registered as objects with string only attributes.
 *
 * The locale data is accessed by its attribute name. The locale entries can use `{parameter}` syntax to refer to data to be inserted in the text.
 */
export class Locale {
	/**
	 * Translate service that provides the pipe operator used to inject and update translations in HTML templates.
	 */
	public static translate: TranslateService = null;

	/**
	 * List of available translation maps.
	 */
	public static translations: Map<string, any> = new Map<string, any>();

	/**
	 * Locale code in use currently.
	 */
	public static code: string = null;

	public static initialize(translate?: TranslateService): void {
		if (!this.code) {
			this.code = 'en';
		}
		
		if (translate) {
			this.translate = translate;
			this.translate.setDefaultLang(this.code);
		}

		for (let i = 0; i < LocaleList.length; i++) {
			Locale.setTranslation(LocaleList[i].code, LocaleList[i].translation);

		}

		Locale.use(this.code);
	}

	/**
	 * Register a locale object, that contains translation for a specific code.
	 *
	 * @param code - Locale code to be used.
	 * @param obj - Object that contains the translation data.
	 */
	public static setTranslation(code: string, obj: any): void {
		if (this.translations.has(code)) {
			throw new Error('Translation code already exists.');
		}

		this.translations.set(code, obj);

		if (this.translate) {
			this.translate.setTranslation(code, obj);
		}
	}

	/**
	 * Set the locale to be used by code.
	 *
	 * @param code - Locale code to be used.
	 */
	public static use(code: string): void {
		this.code = code;

		if (this.translate) {
			this.translate.use(this.code);
		}
	}

	/**
	 * Get a translation entry from the service, can receive parameters swapped in the translation text.
	 * 
	 * If a code is passed as parameter, it will be used to get the translation on the given code. Otherwise, the code in "use" will be used.
	 *
	 * Parameters have to be written as `{parameter}` in the middle of the translation text.
	 *
	 * @param key - Name of the entry to be fetched.
	 * @param parameters - Object with parameters to replace value in the translation.
	 * @param code - The translation code to use instead of the current one in "use".
	 * @returns The translation of the word in the code language in "use" or the provided code as parameter.
	 */
	public static get(key: string, parameters?: any, code?: string): string {
		let text = this.translations.get(code ? code : this.code)[key] || key;
		if (parameters !== undefined) {
			for (const i in parameters) {
				text = text.replace('{' + i + '}', parameters[i]);
			}
		}

		return text;
	}

	/**
	 * Get parameters available for the locale entry.
	 *
	 * Parameters are wrapped around \{camelCase\}.
	 *
	 * @param text - Locale text to get parameters from.
	 * @returns Array with list of all parameters for the translation entry.
	 */
	public static getParams(text: string): string[] {
		const regex = /{(([a-z]+)([A-Z][a-z]+)*)}/g;
		const results: IterableIterator<RegExpMatchArray> = text.matchAll(regex);

		const params: string[] = [];
		let match = results.next();
		while (!match.done) {
			params.push(match.value[1]);
			match = results.next();
		}

		return params;
	}

	/**
	 * Gets the translation of a word/key in all the language codes available on platform.
	 * @param key - The word to be translated.
	 * @param parameters - Object with parameters to replace value in the translation.
	 * @returns An array containing all the translations.
	 */
	public static getAllTranslations(key: string, parameters?: any): string[] {
		return LocaleList.map((l: LocaleSource) => {
			return Locale.get(key, parameters, l.code);
		});
	}

	/**
	 * List of locale codes registered and available.
	 */
	public static list(): string[] {
		return Array.from(this.translations.keys());
	}

	/**
	 * Finds a certain key given a value from the list of translations object
	 * 
	 * @param value - The value of the key
	 * @returns - The list for the keys that have a corresponding value
	 */
	public static getKey(value: string): string {
		const key = Object.keys(LocaleEN).find((searchKey) => {return LocaleEN[searchKey] === value;});
		return key;
	}
}

import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {RepairInspectionResult} from 'src/app/models/repairs/inspections/repair-inspection-result';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {Resource} from 'src/app/models/resource';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type RepairListItemResponse = {
	// UUID of the repair
	uuid: UUID,
	// Description of the repair
	description: string,
	// QR identifier of this repair
	qr: string,
	// Created date of the repair
	updatedAt: Date,
	// Pictures of the damage/issue to repair
	pictures: Resource[],
	// Criticality level
	criticality: number, 
	// Status
	status: number,
	// Inspection
	inspection: {uuid: UUID, date: Date, result: number},
	// Asset information
	asset: {uuid: UUID, name: string, tag: string, fluidFamilyUuid: UUID, fluidTypeUuid: UUID}
}

export type RepairListResponse = {
	// If there are more repairs to fetch
	hasMore: boolean,
	// Repair List
	repairs: RepairListItemResponse[],
	// Request id
	id: number
}

export type RepairListParams = {
	// Status of the repair.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
	// Longevity of the repair
	longevityFilters?: string[],
	// Asset fluid family uuid to filter by
	assetFluidFamilyUuid?: UUID,
	// Asset fluid type uuid to filter by
	assetFluidTypeUuid?: UUID,
	// Parent asset to filter repairs
	parentAssetUuid?: UUID
	// Repair type
	repairTypeUuid?: UUID
};

export type RepairCountParams = {
	// Status of the repair.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Longevity of the repair
	longevityFilters?: string[],
	// Asset fluid family uuid to filter by
	assetFluidFamilyUuid?: UUID,
	// Asset fluid type uuid to filter by
	assetFluidTypeUuid?: UUID,
	// Parent asset to filter repairs
	parentAssetUuid?: UUID
	// Repair type
	repairTypeUuid?: UUID
};

export type RepairListDetailedParams = {
	// Index of the first element to retrieve
	from: number,
	// Number of elements to retrieve
	count: number,
	// Search text to filter list results
	search: string,
	// List of fields to consider in the search
	searchFields: string[],
	// Sort field to sort the repairs by
	sortField: string,
	// Sort direction to sort the repairs by
	sortDirection: string,
	// Status of the repairs to be listed
	status: number,
	// List of longevity filters to filter the repairs by
	longevityFilters: number[],
}

export type RepairListDetailedItem = {
	// Repair data
	repair: Repair,
	// Asset data
	asset: {
		uuid: UUID,
		// Name of the asset
		name: string,
		// Tag of the asset
		tag: string,
		// Manufacturer of the asset
		manufacturer: string,
		// Model of the asset
		model: string,
		// The fluid family of the asset
		fluidFamilyUuid: UUID,
		// The fluid type of the asset
		fluidTypeUuid: UUID
	},
	// Last inspection performed on this repair (if any)
	inspection: {
		// UUID of the inspection
		uuid: UUID,
		// The creation date of this repair
		createdAt: Date,
		// Last update date of the inspection
		updatedAt: Date,
		// The result of the last inspection performed on this repair
		result: typeof RepairInspectionResult[keyof typeof RepairInspectionResult]
	}	
}

export type RepairListDetailedResponse = {
	// If there are more repairs to fetch
	hasMore: boolean,
	// Repairs List
	repairs: RepairListDetailedItem[],
	// Request id
	id: number
}

export class RepairService {
	/**
	 * Get the repair by UUID.
	 *
	 * @param uuid - The UUID of the repair to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Repair object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}

	/**
	* Get the repair by QR code.
	*
	* @param qr - The QR code of the repair to get.
	* @param hideLoading - True to hide the loading spinner, false otherwise.
	* @param displayError - True to display an error message, false otherwise.
	* @returns Repair object.
	*/
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}

	/**
	 * List all Repairs meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The Repairs in the database that match the params.
	 */
	public static async list(params: RepairListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<RepairListResponse> {
		const request = await Service.fetch(ServiceList.repairs.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			repairs: request.response.repairs,
			id: request.id
		};
	}

	/**
	 * List all Repairs meeting the parameters with high detail, including asset and last inspection data.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The Repairs in the database that match the params.
	 */
	public static async listDetailed(params: RepairListDetailedParams, hideLoading: boolean = false, displayError: boolean = true): Promise<RepairListDetailedResponse> {
		const request = await Service.fetch(ServiceList.repairs.listDetailed, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			repairs: request.response.repairs,
			id: request.id
		};
	}

	/**
	 * Count all Repairs meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of Repairs in the database that match the params.
	 */
	public static async count(params: RepairCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.repairs.count, null, null, params, Session.session, hideLoading, displayError);
		
		return request.response.count;
	}
}

import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {Environment} from 'src/environments/environment';
import {Session} from 'src/app/session';
import {Dl50ClientListPage} from './screens/list/dl50-client-list.page';
import {CiresConst} from './dl50-client-const';

@NgModule({
	exports: [],
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		ScrollingModule,
		Dl50ClientListPage
	]
})
export class Dl50ClientModule {
	/**
	 * Check if the user has the Dl50 client role.
	 */
	public static hasRole(): boolean {
		return Dl50ClientModule.isClient() && Session?.user?.roleUuids?.includes(CiresConst.ROLE);
	}

	/**
	 * Check if it is running on cires client.
	 */
	public static isClient(): boolean {
		return !Environment.PRODUCTION || Environment.CLIENT_FEATURES.includes('dl50');
	}
}

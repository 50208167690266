import {WidgetDemo} from 'src/app/modules/dashboard-new/data/widgets/demo-widget/demo-widget';
import {WidgetAssetDistribution} from 'src/app/modules/dashboard-new/data/widgets/asset-distribution-widget/asset-distribution-widget';
import {WidgetAtexInspectionResult} from 'src/app/modules/dashboard-new/data/widgets/atex-inspection-result/atex-inspection-result-widget';
import {WidgetTypes} from './widget-types';

const widgetDemo: any = WidgetDemo;
const widgetAssetDistribution: any = WidgetAssetDistribution;
const widgetAtexInspectionResult: any = WidgetAtexInspectionResult;

export const WidgetsMap: Map<number, any> = new Map([
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_DEMO_WIDGET, widgetDemo],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ASSET_DISTRIBUTION_BY_TYPE_SUBTYPE, widgetAssetDistribution],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ASSET_MAP_ZONE, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_DISTRIBUTION_BY_STATE, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_RESULTS, widgetAtexInspectionResult],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_CARRIED_OUT, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_REVISED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_UPDATED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_INSPECTION_COUNT_BY_STATE, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_INSPECTION_ANSWER_DISTRIBUTION, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_ACTION_PLAN_COUNT_BY_STATE, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_ACTION_PLAN_REJECTED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_ACTION_PLAN_EXECUTED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_ACTION_PLAN_BLOCKED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_GAP_SOLVED, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_REPAIR_BY_STATE, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_REPAIR_BY_CRITICALITY, ''],
	[WidgetTypes.DASHBOARD_WIDGET_TYPE_REPAIR_INSPECTIONS_BY_STATE, '']
]);

import {NgClass} from '@angular/common';
import {Component, ViewEncapsulation, forwardRef, ElementRef, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule, ControlValueAccessor} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CssNgStyle} from 'src/app/utils/css-ng-style';
import {ValidationUtils} from 'src/app/utils/validation-utils';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {InputValidator} from '../uno-input/input-validator';
import {UnoTextComponent} from '../../uno/uno-text/uno-text.component';

@Component({
	selector: 'uno-password',
	templateUrl: './uno-password.component.html',
	styleUrls: ['./uno-password.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoPasswordComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [FormsModule, TranslateModule, NgClass, UnoIconComponent, UnoTextComponent]
})
export class UnoPasswordComponent implements ControlValueAccessor {
	@ViewChild('password', {static: true})
	public passwordInput: ElementRef = null;
	
	/**
	 * Password value
	 */
	public value: string = null;

	/**
	 * Allow the input to be disabled.
	 */
	public disabled: boolean = false;

	/**
	 * Password validator
	 */
	public validator: InputValidator = new InputValidator(ValidationUtils.validPassword, 'passwordSizeError');;

	/**
	 * Style applied to the element.
	 */
	public inputStyle: CssNgStyle = {
		outline: 'none',
		borderStyle: 'none',
		boxSizing: 'border-box',
		textIndent: '16px',
		borderRadius: '4px',
		boxShadow: 'none',
		MozAppearance: 'textfield',
		webkitAppearance: 'caret',
		appearance: 'textfield',
		width: '100%',
		height: '100%',
		'border-radius': '4px',
		border: 'solid 1px var(--gray-8)',
		'font-family': 'Open Sans',
		'font-size': '14px',
		'font-weight': 'normal',
		'font-stretch': 'normal',
		'font-style': 'normal',
		'line-height': 'normal',
		'letter-spacing': 'normal',
		color: 'var(--light-gray-8)',
		'background-color': 'var(--light-gray-12)'
	};

	/**
	 * Shows or hides the written password
	 */
	public togglePassword(): void {
		this.passwordInput.nativeElement.type = this.passwordInput.nativeElement.type === 'password' ? 'text' : 'password';
	}

	public icon(): string {
		if (this.passwordInput.nativeElement.type === 'password') {
			return './assets/icons/uno/hidden.svg';
		} else {
			return './assets/icons/uno/visible.svg';
		}
	}

	/**
	 * Check if the password is valid.
	 */
	public valid(): boolean {
		if (this.validator) {
			return this.validator.validator(this.value);
		}

		return true;
	}

	/**
	 * Validate the input value.
	 */
	public validate(): void {
		if (this.validator) {
			const valid = this.validator.validator(this.value);
			Object.assign(this.inputStyle, {border: valid ? 'solid 1px var(--success-normal)' : 'solid 1px var(--error-normal)'});
		}
	}

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value of the input.
	 * 
	 * @param value - value to update the input with.
	 */
	public updateValue(value: any): void {
		this.value = value.target.value;
		this.validate();
		this.onChange(this.value);
	}

	public writeValue(value: any): void {
		this.value = value;
		this.validate();
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}

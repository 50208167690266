/**
 * Animation timer is a special type of timer that uses the requestAnimationFrame method.
 *
 * This timer calls the method with the same rate as the screen refresh rate.
 * 
 * Loop time can be changed dynamically.
 */
export class AnimationTimer {
	/**
	 * Timer callback method used to process timer ticks.
	 */
	public callback: (time: number)=> void;

	/**
	 * If true the timer is running.
	 */
	public running: boolean = false;

	/**
	 * ID returned by the requestAnimationFrame() method used to control the timer.
	 */
	public id: number = 0;

	public constructor(callback: (time: number)=> void) {
		this.callback = callback;

		this.running = false;
		this.id = -1;
	}

	/**
	 * Start timer, is the timer is already running doesn't do anything.
	 */
	public start(): void {
		if (this.running) {
			return;
		}

		const loop = (time: number): void => {
			this.callback(time);

			if (this.running) {
				this.id = requestAnimationFrame(loop);
			}
		};

		this.running = true;
		this.id = requestAnimationFrame(loop);
	}

	/**
	 * Stop animation timer.
	 */
	public stop(): void {
		this.running = false;
		cancelAnimationFrame(this.id);
	}
}

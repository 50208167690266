<div style="display: flex; align-items: center; width: 100%; height: 100%; flex-direction: column; position: relative;">
	<div style="position: absolute; top: 10px; left: 10px; display: flex; gap: 10px; z-index: 3;">
		<uno-button [icon]="'./assets/dashboards/pin-icon.svg'" [height]="'35px'" [color]="this.displayMode() === dashboardMainPageDisplayMode.PINNED ? 'special-blue' : 'none'" (click)="this.setDisplay(dashboardMainPageDisplayMode.PINNED)">{{'Fixed Dashboards' | translate}}</uno-button>
		<uno-button [icon]="'./assets/dashboards/list-icon.svg'" [height]="'35px'" [color]="this.displayMode() === dashboardMainPageDisplayMode.LIST ? 'special-blue' : 'none'" (click)="this.setDisplay(dashboardMainPageDisplayMode.LIST)">{{'Dashboards List' | translate}}</uno-button>
	</div>

	@if (this.displayMode() === dashboardMainPageDisplayMode.PINNED) {
		<div style="height: calc(100% - 25px); position: relative; top: 30px;width: 100%;">
			<uno-tab>
				@for (dashboard of this.pinnedDashboards; track dashboard; let  i = $index) {
					<uno-tab-section [title]="dashboard.name" [active]="i === 0" [borders]="false">
						<uno-dashboard [dashboard]="dashboard"></uno-dashboard>
					</uno-tab-section>
				}
			</uno-tab>
		</div>
	} @else if (this.displayMode() === dashboardMainPageDisplayMode.LIST) {
		<div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
			<div style="width: 100%; height: 145px; padding: 30px 10px; margin-bottom: 10px">
				<!-- Search bar -->
				<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearchChange($event)"></uno-searchbar>

				<!-- Filters -->
				<div style="overflow-x: auto; display: flex; align-items: flex-end; justify-content: flex-start; flex-direction: row; gap: 5px; width: 100%; height:80px">
					<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
				</div>
			</div>

			<div style="width: 100%; height: calc(100% - 205px); overflow: hidden;">
				<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [labelShown]="true" [selectable]="false" (rowClick)="app.navigator.navigate('/menu/dash-boards/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
				</uno-responsive-table-list>
			</div>
		</div>
	}
</div>
